export function calculateCommissionAmount(
  inputAmount,
  outputAmount,
  pointsOfDifference,
  marketOrTypeRate,
  inputCurrencyId,
  exchangeRate,
  code,
  isPurchase,
  type,
  px,
  exchangeRateCell,
) {
  let commissionAmount;

  console.log('Frontend - Valores recibidos:');
  console.log({
    inputAmount,
    outputAmount,
    pointsOfDifference,
    marketOrTypeRate,
    inputCurrencyId,
    exchangeRate,
    code,
    isPurchase,
    type,
    px,
  });

  switch (code) {
    case 1:
      commissionAmount =
        inputCurrencyId.currency === 'USD'
          ? (inputAmount * pointsOfDifference) / exchangeRate
          : (outputAmount * pointsOfDifference) / exchangeRate;
      console.log('commissionAmount', commissionAmount);
      break;

    case 2:
      commissionAmount =
        inputCurrencyId.currency === 'USD'
          ? (outputAmount * marketOrTypeRate * pointsOfDifference) /
            exchangeRate
          : (inputAmount * marketOrTypeRate * pointsOfDifference) /
            exchangeRate;
      console.log('commissionAmount', commissionAmount);
      break;

    case 3:
      commissionAmount =
        inputCurrencyId.currency === 'ARS'
          ? (outputAmount * marketOrTypeRate - inputAmount) / exchangeRateCell
          : (inputAmount * marketOrTypeRate - outputAmount) / exchangeRateCell;
      console.log('commissionAmount', commissionAmount);
      break;

    case 4:
      commissionAmount =
        inputCurrencyId.currency === 'ARS'
          ? (outputAmount * marketOrTypeRate - inputAmount) / exchangeRateCell
          : (inputAmount * marketOrTypeRate - outputAmount) / exchangeRateCell;
      console.log('commissionAmount', commissionAmount);
      break;

    case 5:
      commissionAmount =
        inputCurrencyId.currency === 'USD'
          ? (outputAmount * marketOrTypeRate * pointsOfDifference) /
            marketOrTypeRate
          : (inputAmount * marketOrTypeRate * pointsOfDifference) /
            marketOrTypeRate;
      console.log('commissionAmount', commissionAmount);
      break;

    case 6:
      commissionAmount =
        inputCurrencyId.currency === 'USD'
          ? (inputAmount * pointsOfDifference) / marketOrTypeRate
          : (outputAmount * pointsOfDifference) / marketOrTypeRate;
      console.log('commissionAmount', commissionAmount);
      break;

    case 7:
      commissionAmount =
        inputCurrencyId.currency === 'ARS'
          ? (outputAmount * marketOrTypeRate - inputAmount) / exchangeRateCell
          : (inputAmount * marketOrTypeRate - outputAmount) / exchangeRateCell;
      console.log('commissionAmount', commissionAmount);
      break;

    case 8:
      commissionAmount =
        inputCurrencyId.currency === 'USD'
          ? (outputAmount * marketOrTypeRate * pointsOfDifference) /
            marketOrTypeRate
          : (inputAmount * marketOrTypeRate * pointsOfDifference) /
            marketOrTypeRate;
      console.log('commissionAmount', commissionAmount);
      break;

    case 9:
      commissionAmount =
        inputCurrencyId.currency === 'ARS'
          ? (outputAmount * marketOrTypeRate - inputAmount) / exchangeRateCell
          : (inputAmount * marketOrTypeRate - outputAmount) / exchangeRateCell;
      console.log('commissionAmount', commissionAmount);
      break;

    case 10:
      commissionAmount =
        inputCurrencyId.currency === 'USD'
          ? (inputAmount * pointsOfDifference) / exchangeRate
          : (outputAmount * pointsOfDifference) / exchangeRate;
      console.log('commissionAmount para USDT', commissionAmount, exchangeRate);
      break;

    default:
      console.log('default');
  }

  const isPxMinorThanType = Number(type) > Number(px);
  const mustBeNegative = isPxMinorThanType && !isPurchase;

  console.log('Frontend - isTypeMinorThanPx:', isPxMinorThanType);
  console.log('Frontend - mustBeNegative:', mustBeNegative);

  if (mustBeNegative) {
    commissionAmount = -Math.abs(commissionAmount);
  } else {
    commissionAmount = Math.abs(commissionAmount);
  }

  console.log(
    'Frontend - Resultado final de commissionAmount:',
    commissionAmount,
  );

  return commissionAmount;
}
