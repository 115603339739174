import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStock from '../Stock/useStock';
import { useOperations } from '../Operations/useOperations';
import useClients from '../Clients/useClients';
import fetchFromApi from '../../utils/fetchFromApi';

const useBusinessUnits = (notificationClicked) => {
  const { getClients, clients } = useClients();

  const [loading, setLoading] = useState(true);
  const [gains, setGains] = useState({
    gainUSD: 0,
    gainCambio: 0,
    gainUSDT: 0,
    gainCable: 0,
    gainSmallFace: 0,
  });

  useEffect(() => {
    console.log('gains', gains.gainUSD);
  }, [gains.gainUSD]);

  const [debts, setDebts] = useState({
    usdtDebt: 0,
    cableDebt: 0,
  });

  const [currentAccountsTotalUSD, setCurrentAccountsTotalUSD] = useState(0);
  const [currentAccountsTotalARS, setCurrentAccountsTotalARS] = useState(0);
  const [anomalies, setAnomalies] = useState([]);
  const [totalAnomalieValueUSD, setTotalAnomalieValueUSD] = useState(0);
  const [totalAnomalieValueArs, setTotalAnomalieValueArs] = useState(0);

  const navigate = useNavigate();
  const { getStock, stock } = useStock();
  const { getOperations, operations } = useOperations();

  const getAnomaliesArs = async (anomalies) => {
    try {
      const anomaliesArs = anomalies.filter(
        (anomalie) => anomalie.currencyName === 'ARS',
      );
      return anomaliesArs;
    } catch (error) {
      console.error('Error fetching anomalies: ', error);
      return [];
    }
  };

  const getAnomaliesUsd = async (anomalies) => {
    try {
      const anomaliesUsd = anomalies.filter(
        (anomalie) => anomalie.currencyName === 'USD',
      );
      return anomaliesUsd;
    } catch (error) {
      console.error('Error fetching anomalies: ', error);
      return [];
    }
  };

  const convertToUSD = (amount, currency, exchangeRate) => {
    if (currency === 'USD') {
      return amount;
    } else if (['ARS', 'BRL'].includes(currency)) {
      return amount / exchangeRate;
    } else {
      return amount * exchangeRate;
    }
  };

  const getExchangeRate = (currency) => {
    const stockCurrency = stock.find(
      (stockItem) => stockItem.currency === currency,
    );
    return stockCurrency?.exchange_rate || 0;
  };

  const calculateGains = (operationType, gainType) => {
    const today = new Date().toDateString();
    const filteredOperations = operations.filter(
      (operation) =>
        new Date(operation.createdAt).toDateString() === today &&
        operation.operation_type === operationType &&
        ['Liquidado', 'Finalizado'].includes(operation.status),
    );

    let totalIncomesUSD = 0;
    let totalExpensesUSD = 0;

    filteredOperations.forEach((operation) => {
      totalIncomesUSD += convertToUSD(
        operation.input_amount,
        operation.input_currency.currency,
        getExchangeRate(operation.input_currency.currency),
      );
      totalExpensesUSD += convertToUSD(
        operation.output_amount,
        operation.output_currency.currency,
        getExchangeRate(operation.output_currency.currency),
      );
    });

    const netGain = totalIncomesUSD - totalExpensesUSD;

    setGains((prevGains) => ({
      ...prevGains,
      [gainType]: netGain,
    }));
  };

  const calculateDebt = (operationType, debtType) => {
    const today = new Date().toDateString();
    const filteredOperations = operations.filter(
      (operation) =>
        new Date(operation.createdAt).toDateString() === today &&
        operation.operation_type === operationType &&
        ['Liquidado', 'Finalizado'].includes(operation.status),
    );

    let totalDebtIncome = 0;
    let totalDebtExpenses = 0;

    totalDebtIncome += filteredOperations
      .filter((operation) => operation.input_currency.currency === 'USD')
      .reduce((acc, operation) => acc + operation.input_amount, 0);

    totalDebtExpenses += filteredOperations
      .filter((operation) => operation.output_currency.currency === 'USD')
      .reduce((acc, operation) => acc + operation.output_amount, 0);

    const netDebt = totalDebtIncome - totalDebtExpenses;

    setDebts((prevDebt) => ({
      ...prevDebt,
      [debtType]: netDebt,
    }));
  };

  const totalAccountsInUSD = (clients) => {
    let totalUSD = 0;

    clients?.forEach((client) => {
      client?.currentAccount?.forEach((account) => {
        const {
          currency: { name: currencyName },
          amount,
        } = account;

        if (currencyName === 'USD') {
          totalUSD += amount;
        }
      });
    });

    setCurrentAccountsTotalUSD(totalUSD);
  };

  const totlaAccountsInArs = (clients) => {
    let totalArs = 0;

    clients?.forEach((client) => {
      client?.currentAccount?.forEach((account) => {
        const {
          currency: { name: currencyName },
          amount,
        } = account;

        if (currencyName === 'ARS') {
          totalArs += amount;
        }
      });
    });

    setCurrentAccountsTotalARS(totalArs);
  };

  const getAnomalies = async () => {
    try {
      const response = await fetchFromApi('GET', 'anomalies/get-all');
      setAnomalies(response);
    } catch (error) {
      console.error('Error fetching anomalies: ', error);
      return [];
    }
  };

  const getCurrencyFromStockId = (stockId) => {
    const stockItem = stock?.find(
      (item) => item._id === stockId._id || item._id === stockId,
    );
    return stockItem?.currency;
  };

  const getTotalAnomaliesValueInARS = (anomalies) => {
    let totalAnomalieValueARS = 0;

    anomalies.forEach((a) => {
      const isPendingSubOperation =
        a.operation[0]?.subOperations?.[a.index]?.status === 'Pendiente' &&
        a.data === 'real';

      const isPendingOperation =
        a.operation[0]?.status === 'Pendiente' && a.data === 'real';

      if (
        isPendingSubOperation ||
        isPendingOperation ||
        a.status !== 'active'
      ) {
        console.log('Anomalie not considered: ', a);
        return;
      }

      if (a.type === 'input') {
        totalAnomalieValueARS += a.amount;
      } else if (a.type === 'output') {
        totalAnomalieValueARS -= a.amount;
      }
    });

    setTotalAnomalieValueArs(totalAnomalieValueARS);
  };

  const getTotalAnomaliesValueInUSD = (anomalies) => {
    let totalAnomalieValueUSD = 0;

    anomalies.forEach((a) => {
      const isPendingSubOperation =
        a.operation[0]?.subOperations?.[a.index]?.status === 'Pendiente' &&
        a.data === 'real';

      const isPendingOperation =
        a.operation[0]?.status === 'Pendiente' && a.data === 'real';

      if (
        isPendingSubOperation ||
        isPendingOperation ||
        a.status !== 'active'
      ) {
        console.log('Anomalie not considered: ', a);
        return;
      }

      const currencyName = getCurrencyFromStockId(a.stock[0]);
      const exchangeRate = getExchangeRate(currencyName);
      const amountInUSD = convertToUSD(a.amount, currencyName, exchangeRate);

      if (a.type === 'input') {
        totalAnomalieValueUSD += amountInUSD;
      } else if (a.type === 'output') {
        totalAnomalieValueUSD -= amountInUSD;
      }
    });

    setTotalAnomalieValueUSD(totalAnomalieValueUSD);
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await Promise.all([
        getStock(),
        getOperations(),
        getClients(),
        getAnomalies(),
      ]);
      setLoading(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadAnomaliesArs = async (anomalies) => {
      const anomaliesArs = await getAnomaliesArs(anomalies);
      return anomaliesArs;
    };

    const loadAnomaliesUsd = async (anomalies) => {
      const anomaliesUsd = await getAnomaliesUsd(anomalies);
      return anomaliesUsd;
    };

    const processAnomalies = async () => {
      if (!loading && stock && operations && clients && anomalies) {
        const anomaliesArs = await loadAnomaliesArs(anomalies);
        const anomaliesUsd = await loadAnomaliesUsd(anomalies);
        calculateGains('COMP.VTA.BILLETE', 'gainCambio');
        calculateGains('CARACHICA/MANCHADO/CAMBIO', 'gainSmallFace');
        calculateGains('COMP.VTA.USDT', 'gainUSDT');
        calculateGains('SUBIDA CABLE', 'gainCable');
        calculateGains('BAJADA CABLE', 'gainCable');
        totalAccountsInUSD(clients);
        totlaAccountsInArs(clients);
        getTotalAnomaliesValueInUSD(anomaliesUsd);
        getTotalAnomaliesValueInARS(anomaliesArs);
        calculateDebt('COMP.VTA.USDT', 'usdtDebt');
        calculateGains('SUBIDA CABLE', 'cableDebt');
        calculateGains('BAJADA CABLE', 'cableDebt');
      }
    };

    processAnomalies();
  }, [loading, stock, operations, clients, anomalies]);

  useEffect(() => {
    setGains((gains) => ({
      ...gains,
      gainUSD:
        gains.gainCambio +
        gains.gainUSDT +
        gains.gainCable +
        gains.gainSmallFace,
    }));
  }, [gains.gainCambio, gains.gainUSDT, gains.gainCable]);

  useEffect(() => {
    if (notificationClicked.isClicked) {
      navigate('/operaciones');
    }
  }, [notificationClicked.isClicked]);

  const actualBalanceUsd = stock?.find(
    (stockItem) => stockItem.currency === 'USD',
  )?.actual_balance;

  const actualBalanceArs = stock?.find(
    (stockItem) => stockItem.currency === 'ARS',
  )?.actual_balance;

  return {
    gains,
    currentAccountsTotalUSD,
    currentAccountsTotalARS,
    totalAnomalieValueUSD,
    totalAnomalieValueArs,
    loading,
    actualBalanceUsd,
    actualBalanceArs,
    debts,
    getExchangeRate,
  };
};

export default useBusinessUnits;
