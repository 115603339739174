export const determineCode = (inputCurrencyName, outputCurrencyName) => {
  const currencyPair = `${inputCurrencyName} y ${outputCurrencyName}`;

  switch (currencyPair) {
    case 'USD y ARS':
    case 'ARS y USD':
      return 1;
    case 'USD y EURO CHICO':
    case 'EURO CHICO y USD':
      return 2;
    case 'ARS y EURO CHICO':
    case 'EURO CHICO y ARS':
      return 3;
    case 'ARS y EURO GRANDE':
    case 'EURO GRANDE y ARS':
      return 4;
    case 'USD y EURO GRANDE':
    case 'EURO GRANDE y USD':
      return 5;
    case 'BRL y USD':
    case 'USD y BRL':
      return 6;
    case 'BRL y ARS':
    case 'ARS y BRL':
      return 7;
    case 'LIBRAS y USD':
    case 'USD y LIBRAS':
      return 8;
    case 'LIBRAS y ARS':
    case 'ARS y LIBRAS':
      return 9;
    case 'USD y USDT':
    case 'USDT y USD':
      return 10;
    default:
      return null;
  }
};
