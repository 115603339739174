import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import theme from '../../../theme';
import { operationTypes } from '../../../utils/constants';
import { NumericFormat } from 'react-number-format';
import formatNumber from '../../../utils/formatNumber';
import DeliveryTable from '../../../components/Operation/DeliveryTable';
import Loader from '../../../components/Loader/Loader';
import { FormLoaderContainer } from '../operationsStyles';
import { useOperations } from '../useOperations';
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

const DeliveryPositionForm = ({
  handlePositionChange,
  usersDelivery,
  selectedUser,
  position,
  setPosition,
  stock,
  opType,
  handleInputChange,
  operation,
  deliveryPosition,
  errors,
  valueReciboEntra,
  rowSelectionModel,
  setRowSelectionModel,
  changeOperationStatus,
  changeSubOperationStatus,
  selectValue,
  setSelectValue,
  rows,
  getDeliveryOperations,
  deliveryOperations,
  setSelectedUser,
  isDeliveriesLoading,
  isStockLoading,
  loading,
  deliveryId,
  isDisabled,
  setIsDisabled,
  isDownloadingAll,
  setIsDownloadingAll,
}) => {
  const { receiveAllPositions } = useOperations();
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (deliveryPosition?.length > 0) {
      const hasPositiveBalance = deliveryPosition.some(
        (position) => position.balance > 0,
      );
      setIsDownloadDisabled(!hasPositiveBalance);
    }
  }, [deliveryPosition]);

  useEffect(() => {
    if (position.userId !== undefined || deliveryId !== undefined) {
      getDeliveryOperations(position.userId || deliveryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.userId]);
  return isStockLoading && isDeliveriesLoading && loading ? (
    <FormLoaderContainer>
      <Loader style={{ marginTop: '50px' }} />
    </FormLoaderContainer>
  ) : (
    <>
      {/* TIPO DE OPERACION */}
      <FormControl variant="standard" fullWidth>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="input"
        >
          Tipo de Operacion
        </InputLabel>
        <Select
          labelId="input"
          name="operation_type"
          onChange={(event) => handleInputChange(event)}
          value={operation.operation_type}
        >
          <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i > 7) return null;
            return (
              <MenuItem key={i} value={o}>
                {o}
              </MenuItem>
            );
          })}
          <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i < 8) return null;
            return (
              <MenuItem key={i} value={o}>
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Autocomplete
        options={usersDelivery}
        getOptionLabel={(usersDelivery) => usersDelivery.name}
        value={selectedUser}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            error={errors.userId}
            {...params}
            label="Deliverys"
            variant="standard"
            fullWidth={true}
            InputLabelProps={{
              style: {
                color: `${theme.palette.inputText.main}`,
              },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
            }}
          />
        )}
        onChange={(event, value) => {
          setPosition({
            ...position,
            userId: value._id,
          });
          setSelectedUser(value);
        }}
      />

      {opType.startsWith('RECIBO') ? (
        <>
          <h6>Posicion del delivery: </h6>
          {deliveryPosition?.map((position, i) => (
            <p key={i}>
              <strong>{position?.currency?.name} </strong>
              {formatNumber(position?.balance)}
            </p>
          ))}{' '}
          {position.userId !== undefined ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setIsDownloadingAll(true);
                receiveAllPositions(position.userId).finally(() => {
                  setIsDownloadingAll(false);
                  navigate('/stock');
                });
              }}
              disable={isDownloadingAll || isDownloadDisabled}
              title={
                isDownloadingAll
                  ? 'Descargando...'
                  : isDownloadDisabled
                  ? 'Sin posiciones con saldo'
                  : 'Descargar todo'
              }
            />
          ) : null}
        </>
      ) : null}
      {opType.startsWith('RECIBO') && position.userId !== undefined ? (
        <>
          <h6>Operaciones de </h6>
          <DeliveryTable
            deliveryOperations={deliveryOperations}
            valueReciboEntra={valueReciboEntra}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            changeOperationStatus={changeOperationStatus}
            changeSubOperationStatus={changeSubOperationStatus}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            rows={rows}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        </>
      ) : null}

      {opType.startsWith('RETIRO') ? (
        <FormControl variant="standard" error={errors.currency}>
          <InputLabel
            sx={{ color: `${theme.palette.inputText.main}` }}
            id="output"
          >
            Divisa de Salida
          </InputLabel>
          <Select
            labelId="output"
            onChange={(event) => handlePositionChange(event)}
            name="currency"
          >
            {stock?.map((s) => {
              return (
                <MenuItem value={s._id} key={s._id} disabled={s.isClosed}>
                  {s.currency}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl
          variant="standard"
          error={errors.currency && !isDownloadingAll}
        >
          <InputLabel
            sx={{ color: `${theme.palette.inputText.main}` }}
            id="input"
          >
            Divisa
          </InputLabel>
          <Select
            labelId="input"
            onChange={(event) => handlePositionChange(event)}
            name="currency"
          >
            {stock?.map((s) => {
              return (
                <MenuItem value={s._id} key={s._id} disabled={s.isClosed}>
                  {s.currency}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <NumericFormat
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        customInput={TextField}
        variant="standard"
        label="Monto"
        allowNegative={false}
        onValueChange={(values) =>
          handlePositionChange({
            target: { value: values.floatValue, name: 'balance' },
          })
        }
        error={errors.balance && !isDownloadingAll}
        name="balance"
      />
    </>
  );
};

export default DeliveryPositionForm;
