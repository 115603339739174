import fetchFromApi from '../../utils/fetchFromApi';
import { useState, useContext, createContext } from 'react';
import useNotistack from '../Notistack/useNotistack';
import useUsers from '../Team/useUsers';
import { useNavigate } from 'react-router-dom';
import validateOperation from './operationsValidations';
import useNotifications from '../Notifications/useNotifications';
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';
import { operationRoles } from '../../utils/constants';
import { useSocket } from '../../utils/socket';
import useLogistic from '../Logistic/useLogistic';
import useAuth from '../Login/useAuth';

const OperationsContext = createContext();

export const OperationsProvider = ({ children }) => {
  const user = useAuth();
  const [currentOperation, setCurrentOperation] = useState(null);
  const [operations, setOperations] = useState([]);
  const [operationsWithoutDeliveries, setOperationsWithoutDeliveries] =
    useState([]);
  const [numberOfPagesWithoutDeliveries, setNumberOfPagesWithoutDeliveries] =
    useState(1);

  const [operationsWithDeliveries, setOperationsWithDeliveries] = useState([]);
  const [numberOfPagesWithDeliveries, setNumberOfPagesWithDeliveries] =
    useState(1);
  const [deliveryOperations, setDeliveryOperations] = useState([]);
  const [operation, setOperation] = useState({});
  const { getProfile } = useUsers();
  const { showNotification } = useNotistack();

  const { socket } = useSocket();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    client: false,
    operation_type: false,
    input_currency: false,
    input_amount: false,
    output_currency: false,
    output_amount: false,
    liquidator_type: false,
    input_anomaly: false,
    output_anomaly: false,
    assignedDelivery: false,
    address: false,
    addressDetail: false,
    exchange_rate: false,
    paidTeamMember: false,
    broker: false,
    balance: false,
    currency: false,
    userId: false,
    commission: false,
  });

  const { createNotification, createNotificationCms } = useNotifications();
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingWithoutDeliveries, setLoadingWithoutDeliveries] =
    useState(true);
  const [loadingWithDeliveries, setLoadingWithDeliveries] = useState(true);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [operationsCSV, setOperationsCSV] = useState([]);
  const [allOperations, setAllOperations] = useState([]);
  const [cotizations, setCotizations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    status: [],
    operationType: [],
    liquidatorType: [],
    clientName: null,
    search: null,
    inputCurrency: 'Todas',
    outputCurrency: 'Todas',
    dateRange: null,
    changed: false,
  });
  const [postSave, setPostSave] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const [filtersScopy, setFiltersCopy] = useState(filters); // eslint-disable-line
  const [isLoading, setIsLoading] = useState(false);
  const [operationsUnchecked, setOperationsUnchecked] = useState([]);
  const [operationsChecked, setOperationsChecked] = useState([]);

  const { filter } = useLogistic();

  const createOperation = async (operation, profile) => {
    if (validateOperation(operation)) {
      const [errors, message] = validateOperation(operation);
      setErrors(errors);
      showNotification(message, 'error');
      throw new Error(message);
    }
    try {
      setLoading(true);

      const response = await fetchFromApi('POST', 'operation', operation);

      const hasSubOps = operation.subOperations
        ?.map((e, index) => ({ ...e, index }))
        .filter((e) => e.liquidator_type === 'DELIVERY');

      if (
        operation.liquidator_type === 'DELIVERY' &&
        operation.assignedDelivery
      ) {
        getProfile(profile).then((profile) => {
          const tokens = Array.isArray(profile.fcmToken)
            ? profile.fcmToken
            : [profile.fcmToken];

          createNotification({
            title: 'Se te asignó una nueva operación.',
            tokens,
            body: `Dirección: ${operation.address}`,
            navigateTo: {
              stack: 'Operaciones',
              screen: 'OperationDetail',
              id: response[0]._id,
            },
            profile: profile._id,
          });
        });
      } else if (operation.liquidator_type === 'multiple' && hasSubOps.length) {
        hasSubOps?.forEach((e) => {
          getProfile(e.assignedDelivery?._id).then((profile) => {
            const tokens = Array.isArray(profile?.fcmToken)
              ? profile.fcmToken
              : [profile?.fcmToken];

            createNotification({
              title: 'Se te asignó una nueva operación.',
              tokens,
              body: `Dirección: ${e.address ? e.address : operation.address}`,
              navigateTo: {
                stack: 'Operaciones',
                screen: 'OperationDetail',
                id: response[0]._id,
                index: e.index.toString(),
              },
              profile: profile?._id,
            });
          });
        });
      } else if (!operationFinacialOrinstitutional(operation.operation_type)) {
        const operationRole = operationRoles(operation.operation_type);
        createNotificationCms(
          'Se creó una nueva operación.',
          `Tipo: ${response[0].operation_type}`,
          response[0]._id,
          response[0].index,
          operationRole,
        );

        socket?.emit('new-operation', JSON.stringify(response[0]));
      }

      showNotification(response[1], 'success');
      setLoading(false);
      return response[0];
    } catch (error) {
      console.log(error);
      showNotification(error.response.data.error, 'error');
      setLoading(false);
      throw error;
    }
  };

  const getOperations = async (filters, page, context) => {
    setLoading(true);
    try {
      const operations = await fetchFromApi(
        'GET',
        `operation/?page=${page}&operation_type=${filters?.operationType}&status=${filters?.status}&clientName=${filters?.clientName}&liquidator_type=${filters?.liquidatorType}&search=${filters?.search}&inputCurrency=${filters?.inputCurrency}&outputCurrency=${filters?.outputCurrency}&dateRange=${filters?.dateRange}&context=${context}`,
      );

      if (user?.role === 'tesoreria') {
        const filteredOperationsUnchecked =
          operations.operationsUnchecked.docs.filter(
            (operation) =>
              operation.status !== 'Pendiente' ||
              operation.checkedByCaja?.isChecked,
          );
        const filteredOperationsChecked =
          operations.operationsChecked.docs.filter(
            (operation) => operation.checkedByCaja?.isChecked,
          );

        setOperationsUnchecked(filteredOperationsUnchecked);
        setOperationsChecked(filteredOperationsChecked);
        setNumberOfPages(operations.operationsUnchecked.totalPages);
      } else {
        setOperations(operations.docs);
        setNumberOfPages(operations.totalPages);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data?.[0]?.[0] === 'Invalid Data, _id')
        showNotification('El ID introducido es invalido', 'error');
    }
    setLoading(false);
  };

  const getOperationsWithouDeliveries = async (filters, page, context) => {
    setLoadingWithoutDeliveries(true);
    try {
      const operations = await fetchFromApi(
        'GET',
        `operation/all/without-deliveries/?page=${page}&status=${filters?.status}&context=${context}`,
      );
      if (operations) {
        setOperationsWithoutDeliveries(operations.docs);
        setNumberOfPagesWithoutDeliveries(operations.totalPages);
        return operations.docs;
      }
    } catch (error) {
      console.log(error);
      if (error.response.data[0][0] === 'Invalid Data, _id')
        showNotification('El ID introducido es invalido', 'error');
    } finally {
      setLoadingWithoutDeliveries(false);
    }
  };

  const getOperationsWithDeliveries = async (filters, page, context) => {
    setLoadingWithDeliveries(true);
    try {
      const operations = await fetchFromApi(
        'GET',
        `operation/all/with-deliveries/?page=${page}&status=${filters?.status}&context=${context}`,
      );

      if (operations.docs.length > 0) {
        setOperationsWithDeliveries(operations.docs);
        setNumberOfPagesWithDeliveries(operations.totalPages);
        return operations.docs;
      } else {
        setNumberOfPagesWithDeliveries(0);
        setLoadingWithDeliveries(false);
      }
    } catch (error) {
      console.error('Error fetching operations:', error);
      showNotification('Error al cargar las operaciones con entregas', 'error');
      setLoadingWithDeliveries(false);
    } finally {
      setLoadingWithDeliveries(false);
    }
  };

  const getDeliveryOperations = async (id) => {
    setLoading(true);
    try {
      const deliveryOperations = await fetchFromApi(
        'GET',
        `operation/confirmed/${id}?delOps=${true}`,
      );

      setDeliveryOperations(deliveryOperations);
    } catch (error) {
      console.log(error);
      if (error.response.data[0][0] === 'Invalid Data, _id')
        showNotification('El ID introducido es invalido', 'error');
    }
    setLoading(false);
  };

  const deleteOperation = async (id, context = 'postDeletion') => {
    try {
      const result = await fetchFromApi('DELETE', 'operation', id);
      if (result) {
        getOperations({}, 1, context);
      }
      if (context !== 'multi') showNotification(result[1], 'success');
    } catch (error) {
      console.log(error);
      showNotification(
        'Ha ocurrido un error al eliminar la operación.',
        'error',
      );
    }
  };

  const updateOperation = async (id, operation, profile) => {
    if (validateOperation(operation)) {
      const [errors, message] = validateOperation(operation);
      setErrors(errors);
      return showNotification(message, 'error');
    }
    try {
      setLoading(true);
      const response = await fetchFromApi('PUT', `operation/${id}`, {
        operation,
      });
      showNotification(response[1], 'success');
      getOperations();

      const hasSubOps = operation.subOperations
        ?.map((e, index) => ({ ...e, index }))
        .filter((e) => e.liquidator_type === 'DELIVERY');

      if (
        operation.liquidator_type === 'DELIVERY' &&
        operation.assignedDelivery
      ) {
        getProfile(profile).then((profile) => {
          const tokens = Array.isArray(profile.fcmToken)
            ? profile.fcmToken
            : [profile.fcmToken];

          createNotification({
            title: 'Se te asignó una nueva operación.',
            tokens,
            body: `Dirección: ${operation.address}`,
            navigateTo: {
              stack: 'Operaciones',
              screen: 'OperationDetail',
              id: response[0]._id,
            },
            profile: profile._id,
          });
        });
      } else if (operation.liquidator_type === 'multiple' && hasSubOps.length) {
        hasSubOps?.forEach((e) => {
          getProfile(e.assignedDelivery?._id).then((profile) => {
            const tokens = Array.isArray(profile?.fcmToken)
              ? profile.fcmToken
              : [profile?.fcmToken];

            createNotification({
              title: 'Se te asignó una nueva operación.',
              tokens,
              body: `Dirección: ${e.address ? e.address : operation.address}`,
              navigateTo: {
                stack: 'Operaciones',
                screen: 'OperationDetail',
                id: response[0]._id,
                index: e.index.toString(),
              },
              profile: profile?._id,
            });
          });
        });
      }

      setOperationsWithDeliveries([]);
      setOperationsWithoutDeliveries([]);
      navigate('/operaciones');
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeDeliveryOperation = async (opId, delId, subOpId) => {
    try {
      let response;
      if (subOpId) {
        response = await fetchFromApi(
          'PUT',
          `operation/change-delivery/?opId=${opId}&delId=${delId}&subOpId=${subOpId}`,
        );
      } else {
        response = await fetchFromApi(
          'PUT',
          `operation/change-delivery/?opId=${opId}&delId=${delId}`,
        );
      }

      if (delId !== 'null' && !subOpId) {
        getProfile(delId).then((profile) => {
          const tokens = Array.isArray(profile.fcmToken)
            ? profile.fcmToken
            : [profile.fcmToken];

          createNotification({
            title: 'Se te asignó una nueva operación.',
            tokens, // Estructura actualizada con tokens como array
            body: `Dirección: ${response[0].address}`,
            navigateTo: {
              stack: 'Operaciones',
              screen: 'OperationDetail',
              id: response[0]._id,
            },
            profile: profile._id,
          });
        });
      } else if (delId !== 'null' && subOpId) {
        getProfile(delId).then((profile) => {
          const tokens = Array.isArray(profile.fcmToken)
            ? profile.fcmToken
            : [profile.fcmToken];

          const subOp = response[0].subOperations.find(
            (subOp) => subOp._id.toString() === subOpId.toString(),
          );

          createNotification({
            title: 'Se te asignó una nueva operación.',
            tokens, // Estructura actualizada con tokens como array
            body: `Dirección: ${
              subOp.address ? subOp.address : response[0].address
            }`,
            navigateTo: {
              stack: 'Operaciones',
              screen: 'OperationDetail',
              id: response[0]._id,
              subOpId: subOpId.toString(),
            },
            profile: profile?._id,
          });
        });
      }

      showNotification(response[1], 'success');
      getOperations();
      getOperationsWithDeliveries(filter, 1, 'logistic');
      getOperationsWithouDeliveries(filter, 1, 'logistic');
    } catch (error) {
      showNotification(
        'Ha ocurrido un error. No se ha podidio cambiar el delivery.',
        'error',
      );
    }
  };

  const changeSubOperationStatus = async (id, index, status) => {
    try {
      const response = await fetchFromApi(
        'PUT',
        `operation/status-sub-op/${id}/?index=${index}`,
        status,
      );

      showNotification(response[0], 'success');
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al cambiar el estado de la sub-operación.',
        'error',
      );
    }
  };

  const changeOperationStatus = async (
    id,
    status,
    context,
    user,
    operation_type,
  ) => {
    try {
      const response = await fetchFromApi(
        'PUT',
        `operation/status/${id}/?name=${user?.name}&email=${user?.email}&operation_type=${operation_type}&role=${user?.role}`,
        status,
      );

      const operationRole = operationRoles(response[1].operation_type);
      createNotificationCms(
        'Se ha modificado el estado de una operación.',
        `Tipo: ${response[1].operation_type}`,
        response[1]._id,
        response[1].index,
        operationRole,
      );

      if (response[2]) {
        showNotification(response[2], 'default');
      }
      socket?.emit('new-operation', JSON.stringify(response[1]));

      if (context !== 'multi') showNotification(response[0], 'success');
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al cambiar el estado de la operación.',
        'error',
      );
    }
  };

  const registerPosition = async (position, type) => {
    position.type = type;
    position.operation_type = type;
    if (validateOperation(position)) {
      const [errors, message] = validateOperation(position);
      setErrors(errors);
      return showNotification(message, 'error');
    }
    try {
      setLoading(true);
      if (position) {
        const response = await fetchFromApi('POST', `position`, {
          position,
        });
        socket?.emit('new-position', JSON.stringify(response[0]));
        showNotification(response[1], 'success');
        getProfile(position.userId).then((profile) => {
          const tokens = Array.isArray(profile.fcmToken)
            ? profile.fcmToken
            : [profile.fcmToken];

          createNotification({
            title: 'Se agregó una nueva posición.',
            tokens,
            body: `Entrá a la aplicación para ver los detalles.`,
            navigateTo: {
              stack: 'Position',
              screen: 'Posicion',
              id: response[0]._id,
            },
            profile: profile._id,
          });
        });

        getOperations();
        setLoading(false);
        navigate('/operaciones');
      } else {
        showNotification('No se pudo registrar la posición.', 'error');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      showNotification(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const receiveAllPositions = async (deliveryId) => {
    try {
      const response = await fetchFromApi('POST', `position/all`, {
        deliveryId,
      });
      showNotification(response.message, 'success');
    } catch (error) {
      console.log(error);
      showNotification(
        'Ha ocurrido un error al descargar las posiciones.',
        'error',
      );
    }
  };

  const getOperationById = async (id) => {
    try {
      setLoading(true);
      const { input_currency, ...response } = await fetchFromApi(
        'GET',
        `operation/${id}`,
      );

      const inputAnomaly = response?.anomalie.find((a) => a.type === 'input');
      const outputAnomaly = response?.anomalie.find((a) => a.type === 'output');

      setOperation({
        ...response,
        input_currency: input_currency?._id || '',
        input_anomaly: inputAnomaly?.amount || '',
        output_anomaly: outputAnomaly?.amount || '',
      });
    } catch (error) {
      console.log(error.response.data);
      throw new Error('Error al obtener la operación');
    } finally {
      setLoading(false);
    }
  };

  const getServiceProivders = async () => {
    setLoading(true);
    try {
      const response = await fetchFromApi('GET', `service-provider`);
      setServiceProviders(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const createNewServiceProvider = async (name) => {
    try {
      const response = await fetchFromApi('POST', `service-provider`, name);

      showNotification(response[0], 'success');
    } catch (error) {
      console.log(error);
      showNotification(
        error.response.data[0] ===
          'Ya hay un servicio/proveedor con este nombre.'
          ? error.response.data[0]
          : 'Ha ocurrido un error al registrar este servicio/proveedor.',
        'error',
      );
    }
  };

  const deleteServiceProvider = async (name) => {
    try {
      const response = await fetchFromApi('DELETE', 'service-provider', name);
      showNotification(response[0], 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const getOperationsForCSV = async () => {
    try {
      const response = await fetchFromApi('GET', 'operation/all/csv');
      setOperationsCSV(response);
      return response;
    } catch (error) {
      showNotification('Ha ocurrido un error al descargar el CSV.', 'error');
    }
  };

  const getAllOperationsWithoutPaginate = async () => {
    try {
      const response = await fetchFromApi('GET', 'operation/all/nopaginate');
      setAllOperations(response);
      return response;
    } catch (error) {
      showNotification('Ha ocurrido un error.', 'error');
    }
  };

  const getCotizations = async () => {
    try {
      const response = await fetchFromApi('GET', 'cotization');
      setCotizations(response);
    } catch (error) {
      console.error(error);
      showNotification(
        'Ha ocurrido un error al cargar las cotizaciones.',
        'error',
      );
    }
  };

  const chargeCotization = async (id, value) => {
    try {
      await fetchFromApi('PUT', `cotization/value/${id}`, value);
      showNotification(
        'Cotizacion cargada, se ha enviado un mensaje al cliente.',
        'success',
      );
    } catch (error) {
      console.error(error);
      showNotification(error.response.data[0], 'error');
    }
  };

  const createSuboperations = async (subOperations, id) => {
    try {
      const sanitizedSubOperations = subOperations?.map((subOp) => {
        return {
          ...subOp,
          inputAmount: isNaN(parseFloat(subOp.inputAmount))
            ? 0
            : parseFloat(subOp.inputAmount),
          outputAmount: isNaN(parseFloat(subOp.outputAmount))
            ? 0
            : parseFloat(subOp.outputAmount),
        };
      });

      if (currentOperation._id) {
        const response = await fetchFromApi(
          'POST',
          `operation/sub-operations/${currentOperation._id}`,
          { subOperations: sanitizedSubOperations },
        );
        if (response) {
          showNotification(response.message, 'success');
          setOperationsWithDeliveries([]);
          setOperationsWithoutDeliveries([]);

          await getOperationsWithDeliveries(filter, 1, 'logistic');
          await getOperationsWithouDeliveries(filter, 1, 'logistic');
        }
      }
    } catch (error) {
      console.error(error);
      showNotification(
        'Ha ocurrido un error al registrar las sub-operaciones.',
        'error',
      );
    }
  };

  const checkOperation = async (id, isChecked, userLocal) => {
    try {
      const response = await fetchFromApi(
        'POST',
        `operation/check-operation/${id}`,
        {
          userId: userLocal.id,
          isChecked: isChecked,
        },
      );

      if (response) {
        showNotification(response.message, 'success');
      }
    } catch (error) {
      console.error(error);
      showNotification(
        'Ha ocurrido un error al validar la operación.',
        'error',
      );
    }
  };

  const getFullUrl = () => {
    const url = window.location.href;
    const urlArray = url.split('/');
    return urlArray[urlArray.length - 1];
  };

  const handleSelectOperation = (e) => {
    const value = JSON.parse(e.target.value);
    if (e.target.checked) {
      setSelected([...selected, value]);
    } else {
      const remove = selected.filter((o) => o.id !== value.id);
      setSelected(remove);
    }
  };

  const handleSelectAllOperations = async (e) => {
    if (e.target.name === 'all-operations') {
      const op = [];
      // eslint-disable-next-line array-callback-return
      allOperations.map((o) => {
        op.push({ id: o._id, type: o.operation_type });
      });
      return setSelected(op);
    }

    if (e.target.checked) {
      setChecked(true);
      const op = [];
      // eslint-disable-next-line array-callback-return
      operations.map((o) => {
        op.push({ id: o._id, type: o.operation_type });
      });
      return setSelected(op);
    } else {
      setChecked(false);
      setSelected([]);
    }
  };

  const handleChangeSelect = async (e) => {
    try {
      let noChange = false;
      let changedCount = 0;

      for (let i = 0; i < selected.length; i++) {
        const s = selected[i];

        if (
          operationFinacialOrinstitutional(s.type) &&
          (e === 'Confirmado LYM' ||
            e === 'Confirmado Cliente' ||
            e === 'Pendiente')
        ) {
          noChange = true;
        } else {
          setIsLoading(true);
          await changeOperationStatus(s.id, { statusValue: e }, 'multi');
          changedCount++;
          setIsLoading(false);
        }
      }

      showNotification(
        `¡El estado de ${changedCount} operaciones se modificó con éxito! El stock ha sido actualizado.`,
        'success',
      );

      if (noChange) {
        showNotification(
          `Algunas operaciones no han cambiado su estado ya que no son compatibles con el estado ${e}`,
          'error',
        );
      }

      setChecked(false);
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al cambiar el estado de las operaciones.',
        'error',
      );
    }

    setSelected([]);
    getOperations({}, page);
  };

  const handleMultipleDelete = async () => {
    try {
      selected.map(async (s) => await deleteOperation(s.id, 'multi'));
      showNotification(
        'Las operaciones se han eliminado con éxito.',
        'success',
      );
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al eliminar las operaciones.',
        'error',
      );
    }
    setSelected([]);
    getOperations({}, page);
  };

  return (
    <OperationsContext.Provider
      value={{
        createOperation,
        getOperations,
        deleteOperation,
        updateOperation,
        changeSubOperationStatus,
        changeDeliveryOperation,
        getOperationById,
        setOperation,
        operations,
        operation,
        errors,
        setErrors,
        numberOfPages,
        loading,
        registerPosition,
        getServiceProivders,
        serviceProviders,
        createNewServiceProvider,
        deleteServiceProvider,
        getOperationsForCSV,
        operationsCSV,
        getAllOperationsWithoutPaginate,
        getCotizations,
        cotizations,
        chargeCotization,
        getDeliveryOperations,
        deliveryOperations,
        getOperationsWithouDeliveries,
        operationsWithoutDeliveries,
        numberOfPagesWithoutDeliveries,
        getOperationsWithDeliveries,
        operationsWithDeliveries,
        numberOfPagesWithDeliveries,
        loadingWithDeliveries,
        loadingWithoutDeliveries,
        setOperationsWithoutDeliveries,
        setOperationsWithDeliveries,
        createSuboperations,
        isModalOpen,
        setIsModalOpen,
        checkOperation,
        currentOperation,
        setCurrentOperation,
        filters,
        setFilters,
        postSave,
        setPostSave,
        anchorEl,
        setAnchorEl,
        page,
        setPage,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selected,
        setSelected,
        checked,
        setChecked,
        filtersScopy,
        isLoading,
        getFullUrl,
        handleSelectOperation,
        handleSelectAllOperations,
        handleChangeSelect,
        handleMultipleDelete,
        changeOperationStatus,
        operationsUnchecked,
        operationsChecked,
        receiveAllPositions,
      }}
    >
      {children}
    </OperationsContext.Provider>
  );
};

export const useOperations = () => useContext(OperationsContext);
