import React, { useEffect, useState } from 'react';
import {
  CorredorContainer,
  CorredorLine,
  CorredorTitle,
  SubOperationFormContainer,
} from '../operationsStyles';
import { NumericFormat } from 'react-number-format';
import { Autocomplete, TextField } from '@mui/material';
import useBrokers from '../../Brokers/useBrokers';
import useStock from '../../Stock/useStock';
import Decimal from 'decimal.js';
import DeleteIcon from '@mui/icons-material/Delete';
import { determineCode } from '../../../utils/determineCode';
import { calculateCommissionAmount } from '../../../utils/calculateCommissionAmount';
import useBusinessUnits from '../../BusinessUnits/useBusinessUnits';

const IntermediaryForm = ({
  operation,
  profile,
  theme,
  filter,
  handleAddBroker,
  errors,
  intermediaryType,
  setOperation,
  context,
  setAddedIntermediary,
  addedIntermediary,
  setShowIntermediaryButton,
  showIntermediaryButton,
  exchangeRate,
}) => {
  const [state, setState] = useState({
    priceMarket: null,
    pointsOfDifference: 0,
    amount: 0,
    intermediaryType: '',
  });
  const [inputCurrencyState, setInputCurrencyState] = useState();
  const [outputCurrencyState, setOutputCurrencyState] = useState();
  const [initialBroker, setInitialBroker] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [isCommissionDivided, setIsCommissionDivided] = useState(false);

  const { brokers, getBrokers, loading } = useBrokers();
  const { getCurrency } = useStock();
  const { getExchangeRate } = useBusinessUnits(false);

  const inputCurrencyId = operation?.input_currency;
  const outputCurrencyId = operation?.output_currency;
  const exchangeRateStock = getExchangeRate('ARS');

  const handlePriceMarket = (values, context, e) => {
    const newPrice = values.floatValue ?? null;
    setState((prevState) => ({
      ...prevState,
      priceMarket: newPrice,
    }));
    setOperation((prevOperation) => ({
      ...prevOperation,
      [context]: newPrice,
    }));
  };

  const handleDeleteIntermediary = () => {
    setOperation({
      ...operation,
      intermediary: null,
      commission: '',
      marketPrice: null,
    });
    handlePriceMarket({ floatValue: null }, 'marketPrice');
    setAddedIntermediary(!addedIntermediary);
    setShowIntermediaryButton(!showIntermediaryButton);
  };

  useEffect(() => {
    getBrokers();
    // eslint-disable-next-line
  }, []);

  const calculatePointsOfDifference = () => {
    const { priceMarket } = state;

    if (priceMarket !== null && operation.exchange_rate) {
      const priceMarketDecimal = priceMarket && new Decimal(priceMarket);
      const exchangeRateDecimal =
        operation?.exchange_rate && new Decimal(operation?.exchange_rate);

      const pointsDifference =
        priceMarketDecimal &&
        exchangeRateDecimal &&
        priceMarketDecimal.minus(exchangeRateDecimal).abs().toNumber();

      setState((prevState) => ({
        ...prevState,
        pointsOfDifference: pointsDifference,
      }));
      setOperation((prevOperation) => ({
        ...prevOperation,
        pointsOfDifference: pointsDifference,
      }));
    }
  };

  useEffect(() => {
    calculatePointsOfDifference();
    // eslint-disable-next-line
  }, [state.priceMarket, operation.exchange_rate]);

  useEffect(() => {
    if (operation.intermediary && operation.liquidator_type === 'DELIVERY') {
      setIsCommissionDivided(true);
    } else {
      setIsCommissionDivided(false);
    }
  }, [operation.intermediary, operation.liquidator_type]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const inputCurrency = await getCurrency(inputCurrencyId);
        const outputCurrency = await getCurrency(outputCurrencyId);

        setInputCurrencyState(inputCurrency);
        setOutputCurrencyState(outputCurrency);

        const code = determineCode(
          inputCurrency?.currency,
          outputCurrency?.currency,
        );
        if (code) {
          setOperation((prevOperation) => ({
            ...prevOperation,
            code: code,
          }));
        }
      } catch (error) {
        console.error('Error al obtener las monedas:', error);
      }
    };

    fetchCurrencies();
    // eslint-disable-next-line
  }, [
    operation.input_currency,
    operation.output_currency,
    inputCurrencyId,
    outputCurrencyId,
  ]);

  const handleCalculateAmount = async () => {
    const { pointsOfDifference, priceMarket } = state;
    const inputAmount = operation?.input_amount;
    const outputAmount = operation?.output_amount;
    const exchangeRateCell =
      operation?.exchangeRateCell || exchangeRateStock || 0;

    if (!inputCurrencyState || !outputCurrencyState) return;

    const inputCurrency = inputCurrencyState?.currency;
    const outputCurrency = outputCurrencyState?.currency;
    const exchangeRateValue = operation.marketPrice || 0;
    const code = determineCode(inputCurrency, outputCurrency);

    if (!code) {
      console.log(
        'No se pudo determinar el código para las divisas proporcionadas.',
      );
      return;
    }

    const isPurchase = operation?.isPurchase || false;
    const type = operation?.exchange_rate || 0;
    const px = operation?.marketPrice || 0;

    const marketOrTypeRate = priceMarket;

    if (
      !inputAmount ||
      !outputAmount ||
      pointsOfDifference === null ||
      !marketOrTypeRate ||
      !inputCurrency ||
      !exchangeRateValue ||
      code === null
    ) {
      return;
    }

    const commissionAmount = calculateCommissionAmount(
      inputAmount,
      outputAmount,
      pointsOfDifference,
      marketOrTypeRate,
      inputCurrencyState,
      exchangeRateValue,
      code,
      isPurchase,
      type,
      px,
      exchangeRateCell,
    );

    let totalCommissionDecimal = new Decimal(commissionAmount);

    let intermediaryCommissionAmount;
    let lymCommissionAmount;
    const isLymColor = operation.liquidator_type === 'DELIVERY';

    if (isLymColor) {
      const commercialCommissionAmount = totalCommissionDecimal.mul(0.65);
      lymCommissionAmount = totalCommissionDecimal.mul(0.35);
      intermediaryCommissionAmount = commercialCommissionAmount;

      await assignLymCommission(
        lymCommissionAmount.toFixed(2, Decimal.ROUND_DOWN),
      );
    } else {
      intermediaryCommissionAmount = totalCommissionDecimal;
    }

    const amount = intermediaryCommissionAmount.toNumber();

    setState((prevState) => ({
      ...prevState,
      amount: amount,
    }));

    setOperation((prevOperation) => ({
      ...prevOperation,
      intermediaryAmount: amount,
      amount: amount,
    }));
  };

  const assignLymCommission = async (commission) => {
    let lymBroker = brokers.find((broker) => broker.name === 'LYM');
    if (!lymBroker) {
      try {
        const newBrokerId = await handleAddBroker('LYM');
        if (newBrokerId) {
          lymBroker = { name: 'LYM', _id: newBrokerId };
          await getBrokers();
        } else {
          console.log('No se pudo crear el intermediario LYM');
          return;
        }
      } catch (error) {
        console.log('Error al agregar el intermediario LYM:', error);
        return;
      }
    }

    setOperation((prevOperation) => ({
      ...prevOperation,
      lymCommission: commission,
      lymBroker: lymBroker._id,
    }));
  };

  const resetAmount = () => {
    setState((prevState) => ({
      ...prevState,
      amount: 0,
    }));
  };

  const handleIntermediaryType = () => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      intermediaryType: intermediaryType,
    }));

    setState((prevState) => ({
      ...prevState,
      intermediaryType: intermediaryType,
    }));
  };

  useEffect(() => {
    handleIntermediaryType();
    // eslint-disable-next-line
  }, [intermediaryType]);

  useEffect(() => {
    if (state.priceMarket === null) {
      resetAmount();
    }
  }, [state.priceMarket]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      priceMarket: operation.marketPrice || prevState.priceMarket,
      pointsOfDifference:
        operation.pointsOfDifference || prevState.pointsOfDifference,
      amount: operation.intermediaryAmount || prevState.amount,
    }));
  }, [
    operation.marketPrice,
    operation.pointsOfDifference,
    operation.intermediaryAmount,
    brokers,
    operation?.intermediary,
  ]);

  useEffect(() => {
    const foundBroker = brokers.find(
      (broker) => broker._id === operation?.intermediary,
    );
    setInitialBroker(foundBroker || null);
  }, [operation?.intermediary, brokers]);

  useEffect(() => {
    calculatePointsOfDifference();
    handleCalculateAmount();
    // eslint-disable-next-line
  }, [
    state.priceMarket,
    operation.exchange_rate,
    operation.input_amount,
    operation.output_amount,
    exchangeRate,
    inputCurrencyState,
    outputCurrencyState,
    operation.pointsOfDifference,
    operation.liquidator_type,
    operation.isPurchase,
    operation.type,
    operation.px,
  ]);

  return loading ? (
    <>
      <CorredorContainer>
        <CorredorTitle>Cargando corredores...</CorredorTitle>
        <CorredorLine />
      </CorredorContainer>
    </>
  ) : (
    <div>
      <CorredorContainer>
        <CorredorTitle>Intermediario</CorredorTitle>
        <CorredorLine />
      </CorredorContainer>
      <SubOperationFormContainer>
        <div
          style={{
            width: '50%',
          }}
        >
          <Autocomplete
            value={context === 'edit' ? initialBroker : selectedBroker}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setSelectedBroker({
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                handleAddBroker(newValue.inputValue)
                  .then((newBrokerId) => {
                    if (newBrokerId) {
                      setSelectedBroker({
                        name: newValue.inputValue,
                        _id: newBrokerId,
                      });
                      setOperation({
                        ...operation,
                        intermediary: newBrokerId,
                      });
                      getBrokers();
                    } else {
                      console.log('No se pudo crear el intermediario');
                    }
                  })
                  .catch((error) => {
                    console.log('Error al agregar el intermediario:', error);
                  });
              } else {
                setSelectedBroker(newValue);
                setOperation({
                  ...operation,
                  intermediary: newValue?._id,
                });
                if (context === 'edit') {
                  setOperation({
                    ...operation,
                    intermediary: newValue?._id,
                  });
                }
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              const isExisting = options.filter(
                (option) =>
                  option.name.toLowerCase() === inputValue.toLowerCase(),
              );

              if (inputValue !== '' && isExisting.length === 0) {
                filtered.push({
                  inputValue,
                  name: `Agregar "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={brokers}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }

              if (option.inputValue) {
                return option.inputValue;
              }

              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            freeSolo
            fullWidth
            disabled={profile?.role === 'liquidador_caja' ? true : false}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.client}
                label="Intermediario"
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                  },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                }}
              />
            )}
          />
        </div>

        <div
          style={{
            width: '50%',
          }}
        >
          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(values, e) =>
              handlePriceMarket(values, 'marketPrice', e)
            }
            customInput={TextField}
            value={
              context === 'edit' ? operation?.marketPrice : state.priceMarket
            }
            variant="standard"
            InputLabelProps={{
              style: {
                color: `${theme.palette.inputText.main}`,
              },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
            }}
            fullWidth
            error={errors.input_amount}
            disabled={profile.role === 'liquidador_caja' ? true : false}
            label="Precio de mercado"
          />
        </div>
        <DeleteIcon
          sx={{
            color: 'white',
            margin: '0',
            padding: '5px',
            cursor: 'pointer',
            backgroundColor: '#f35658',
            borderRadius: '50%',
            width: '25px',
            height: '25px',
            visibility: 'hidden',
          }}
          onClick={() => handleDeleteIntermediary()}
        />
      </SubOperationFormContainer>
      <SubOperationFormContainer>
        <div
          style={{
            width: '50%',
          }}
        >
          <TextField
            label="Puntos de dif."
            variant="standard"
            InputLabelProps={{
              style: { color: `${theme.palette.inputText.main}` },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
              borderBottom: '1px solid #D3D6DA',
            }}
            InputProps={{ disableUnderline: true }}
            fullWidth
            value={
              context === 'edit'
                ? operation?.pointsOfDifference
                : state.pointsOfDifference
            }
            disabled
          />
        </div>
        <div
          style={{
            width: '50%',
            position: 'relative',
          }}
        >
          <NumericFormat
            thousandSeparator=","
            decimalSeparator="."
            fixedDecimalScale={true}
            customInput={TextField}
            value={
              context === 'edit' ? operation?.intermediaryAmount : state.amount
            }
            variant="standard"
            InputLabelProps={{
              style: {
                color: `${theme.palette.inputText.main}`,
              },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
            }}
            fullWidth
            disabled
            label="Monto"
          />
          {isCommissionDivided && (
            <span
              style={{
                position: 'absolute',
                right: '0',
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#4CAF50',
                fontSize: '0.8rem',
              }}
            >
              + {operation.lymCommission} LYM
            </span>
          )}
        </div>
        <DeleteIcon
          sx={{
            color: 'white',
            margin: '0',
            padding: '5px',
            cursor: 'pointer',
            backgroundColor: '#f35658',
            borderRadius: '50%',
            width: '25px',
            height: '25px',
          }}
          onClick={() => handleDeleteIntermediary()}
        />
      </SubOperationFormContainer>
    </div>
  );
};

export default IntermediaryForm;
