import React, { useEffect, useState } from 'react';
import ButtonOutlineComission from '../../../components/Button/ButtonOutlineComission';
import BrokerForm from './BrokerForm';
import IntermediaryForm from './IntermediaryForm';
import useStock from '../../Stock/useStock';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const ComissionAgentsForm = ({
  operation,
  setOperation,
  filter,
  profile,
  errors,
  theme,
  handleAddBroker,
  handleNumericFormatChange,
  context,
}) => {
  const [showAddBrokerButton, setShowAddBrokerButton] = useState(true);
  const [showIntermediaryButton, setShowIntermediaryButton] = useState(true);
  const [addedBroker, setAddedBroker] = useState(false);
  const [addedIntermediary, setAddedIntermediary] = useState(false);
  const [inputCurrencyState, setInputCurrencyState] = useState();
  const [outputCurrencyState, setOutputCurrencyState] = useState();
  const [showInputExchangeRate, setShowInputExchangeRate] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(0);

  const { getCurrency } = useStock();

  const inputCurrencyId = operation?.input_currency;
  const outputCurrencyId = operation?.output_currency;

  const handleExchangeRateChange = (values, e) => {
    const newExchangeRate = values.floatValue || 0;
    setExchangeRate(newExchangeRate);
    setOperation({
      ...operation,
      usdConversionRate: newExchangeRate,
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchCurrencies = async () => {
      try {
        let inputCurrency;
        let outputCurrency;

        if (inputCurrencyId) {
          inputCurrency = await getCurrency(inputCurrencyId);
        }
        if (outputCurrencyId) {
          outputCurrency = await getCurrency(outputCurrencyId);
        }

        if (isMounted && inputCurrency && outputCurrency) {
          setInputCurrencyState(inputCurrency);
          setOutputCurrencyState(outputCurrency);
          if (
            inputCurrency?.currency !== 'USD' &&
            outputCurrency?.currency !== 'USD'
          ) {
            setShowInputExchangeRate(true); // Solo se muestra si ambas monedas no son USD
          } else {
            setShowInputExchangeRate(false); // No se muestra si alguna de las monedas es USD
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error al obtener las monedas:', error);
        }
      }
    };

    fetchCurrencies();

    return () => {
      isMounted = false;
    };
  }, [inputCurrencyId, outputCurrencyId]);

  useEffect(() => {
    if (operation.broker?.length) {
      setAddedBroker(true);
      setShowAddBrokerButton(false);
    }
    if (operation.intermediary?.length) {
      setAddedIntermediary(true);
      setShowIntermediaryButton(false);
    }
  }, [operation]);

  return (
    <div>
      <h3
        style={{
          color: theme.palette.title.main,
        }}
      >
        Comisionistas
      </h3>

      {showInputExchangeRate && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.5rem',
          }}
        >
          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(values, e) => handleExchangeRateChange(values, e)}
            customInput={TextField}
            value={
              context === 'edit' ? operation.usdConversionRate : exchangeRate
            }
            variant="standard"
            InputLabelProps={{
              style: {
                color: `${theme.palette.inputText.main}`,
              },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
            }}
            fullWidth
            disabled={profile.role === 'liquidador_caja' ? true : false}
            label="Tipo de cambio para convertir a USD"
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: '1rem',
          marginBottom: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem',
          }}
        >
          {showAddBrokerButton && (
            <ButtonOutlineComission
              title="AGREGAR CORREDOR"
              onClick={() => {
                setAddedBroker(!addedBroker);
                setShowAddBrokerButton(!showAddBrokerButton);
              }}
            />
          )}
          {showIntermediaryButton && (
            <ButtonOutlineComission
              title="AGREGAR INTERMEDIARIO"
              onClick={() => {
                setAddedIntermediary(!addedIntermediary);
                setShowIntermediaryButton(!showIntermediaryButton);
              }}
            />
          )}
        </div>
      </div>

      {addedBroker && (
        <BrokerForm
          operation={operation}
          setOperation={setOperation}
          filter={filter}
          profile={profile}
          errors={errors}
          theme={theme}
          handleAddBroker={handleAddBroker}
          inputCurrencyState={inputCurrencyState}
          outputCurrencyState={outputCurrencyState}
          setShowAddBrokerButton={setShowAddBrokerButton}
          showAddBrokerButton={showAddBrokerButton}
          addedBroker={addedBroker}
          setAddedBroker={setAddedBroker}
          brokerType="broker"
          context={context}
          exchangeRate={exchangeRate}
        />
      )}
      {addedIntermediary && (
        <IntermediaryForm
          profile={profile}
          operation={operation}
          setOperation={setOperation}
          theme={theme}
          filter={filter}
          handleAddBroker={handleAddBroker}
          setAddedIntermediary={setAddedIntermediary}
          addedIntermediary={addedIntermediary}
          errors={errors}
          handleNumericFormatChange={handleNumericFormatChange}
          intermediaryType="intermediary"
          context={context}
          showIntermediaryButton={showIntermediaryButton}
          setShowIntermediaryButton={setShowIntermediaryButton}
          exchangeRate={exchangeRate}
        />
      )}
    </div>
  );
};

export default ComissionAgentsForm;
