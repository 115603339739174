import { FormControl, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import theme from '../../../theme';
import formatNumber from '../../../utils/formatNumber';
import useUsers from '../../Team/useUsers';
import {
  BottomOperationFormContainer,
  CorredorContainer,
  CorredorLine,
  CorredorTitle,
  SubOperationFormContainer,
} from '../operationsStyles';
import useAuth from '../../Login/useAuth';
import { CurrenciesInputDetail } from './CurrenciesInputDetail';

const DetailForm = ({
  errors,
  handleInputChange,
  selectedClient,
  selectedUser,
  setOperation,
  operation,
  stock,
  inputAnomalie,
  outputAnomalie,
  context,
  setCurrencyChecked,
  intermediaryInfo,
  noIntermediaryInfo,
  isLoading,
}) => {
  const [loading, setLoading] = useState(true);
  const user = useAuth();
  const { getProfile } = useUsers();

  useEffect(() => {
    getProfile(user?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      operation.operation_type === 'ENTREGO/SALE POS.DELIVERY' ||
      operation.operation_type === 'RECIBO/ENTRA POS.DELIVERY'
    ) {
      setLoading(false);
    }
  }, [operation]);

  useEffect(() => {
    if (context !== 'edit') setLoading(false);
    if (selectedClient === undefined || selectedClient === null) return;
    if (operation.liquidator_type === 'DELIVERY') {
      setLoading(false);
      if (selectedUser === undefined || selectedUser === null) return;
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, selectedUser]);

  return (
    <>
      {loading ? (
        <Loader
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: '50px',
          }}
        />
      ) : (
        <>
          <SubOperationFormContainer>
            {/* TIPO DE OPERACION */}
            <FormControl variant="standard" fullWidth disabled={true}>
              <TextField
                id="outlined-basic"
                variant="standard"
                name="operation_type"
                value={operation?.operation_type}
                label="Tipo de operación"
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                    position: 'relative',
                    top: '-10px',
                  },
                }}
                disabled={true}
              />
            </FormControl>

            {/* {CLIENTES} */}
            <TextField
              fullWidth
              variant="standard"
              name="client"
              value={operation?.client?.name}
              disabled={true}
              label="Cliente"
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                  position: 'relative',
                  top: '-10px',
                },
              }}
            />
          </SubOperationFormContainer>

          {operation.useCurrentAccount && <h3>USA CUENTA CORRIENTE.</h3>}
          {operation.createIncomeCurrentAccount && (
            <h3>ACREDITA EN CUENTA CORRIENTE.</h3>
          )}

          <CurrenciesInputDetail
            handleInputChange={handleInputChange}
            stock={stock}
            operation={operation}
            setOperation={setOperation}
            setCurrencyChecked={setCurrencyChecked}
            errors={errors}
          />

          <CorredorContainer>
            <CorredorTitle>Corredor</CorredorTitle>
            <CorredorLine />
          </CorredorContainer>
          <SubOperationFormContainer>
            {/* BROKER */}
            <div
              style={{
                width: '33%',
              }}
            >
              <TextField
                label="Broker"
                variant="standard"
                name="broker"
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                    position: 'relative',
                    top: '-10px',
                  },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  borderBottom: '1px solid #D3D6DA',
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                value={
                  isLoading ? 'Cargando...' : noIntermediaryInfo.name || ''
                }
                disabled={true}
              />
            </div>

            <div>
              <div
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #D3D6DA',
                  color: theme.palette.title.main,
                }}
              >
                <h4
                  style={{
                    margin: '0',
                    padding: '0',
                  }}
                >
                  Comisión:{' '}
                  <span
                    style={{
                      color: 'gray',
                      fontWeight: 'lighter',
                    }}
                  >
                    0.05%
                  </span>
                </h4>
              </div>
            </div>

            <div
              style={{
                width: '33%',
                position: 'relative',
                top: '15px',
              }}
            >
              <TextField
                variant="standard"
                InputLabelProps={{
                  style: { color: `${theme.palette.inputText.main}` },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  borderBottom: '1px solid #D3D6DA',
                }}
                InputProps={{ disableUnderline: true }}
                fullWidth
                value={operation.commission}
                disabled
              />
            </div>
          </SubOperationFormContainer>

          <CorredorContainer>
            <CorredorTitle>Intermediario</CorredorTitle>
            <CorredorLine />
          </CorredorContainer>
          <SubOperationFormContainer>
            {/* INTERMEDIARIO */}
            <TextField
              label="Intermediario"
              variant="standard"
              name="intermediary"
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                  position: 'relative',
                },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={isLoading ? 'Cargando...' : intermediaryInfo.name || ''}
              disabled={true}
            />

            {/* PRECIO MERCADO */}
            <TextField
              label="Precio de mercado"
              variant="standard"
              name="marketPrice"
              disabled={true}
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                  position: 'relative',
                  top: '-10px',
                },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={
                operation?.marketPrice
                  ? formatNumber(operation?.marketPrice)
                  : ''
              }
            />
          </SubOperationFormContainer>

          <SubOperationFormContainer
            style={{
              position: 'relative',
            }}
          >
            <TextField
              label="Puntos de diferencia"
              variant="standard"
              name="pointsOfDifference"
              disabled={true}
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                  position: 'relative',
                  top: '10px',
                },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={
                operation?.pointsOfDifference
                  ? formatNumber(operation?.pointsOfDifference)
                  : ''
              }
            />
            <TextField
              label="Monto"
              variant="standard"
              name="amount"
              disabled={true}
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                  position: 'relative',
                  top: '10px',
                },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={
                operation?.intermediaryAmount
                  ? formatNumber(operation?.intermediaryAmount)
                  : ''
              }
            />
            {operation.lymCommission && (
              <span
                style={{
                  position: 'absolute',
                  right: '0',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#4CAF50',
                  fontSize: '0.8rem',
                }}
              >
                + {operation.lymCommission} LYM
              </span>
            )}
          </SubOperationFormContainer>

          {operation.liquidator_type === 'multiple' ? (
            operation.subOperations.map((subOp, index) => (
              <>
                <div>
                  <span style={{ color: 'red' }}>
                    ---------------- SubOperacion N {index + 1}
                    ----------------
                  </span>
                </div>
                <SubOperationFormContainer>
                  {/* LIQUIDADOR */}
                  <FormControl
                    variant="standard"
                    style={{ marginTop: '15px' }}
                    fullWidth
                    error={errors.liquidator_type}
                  >
                    <InputLabel
                      sx={{
                        color: `${theme.palette.inputText.main}`,
                        position: 'relative',
                        top: '-25px',
                      }}
                      id="liquidador"
                    >
                      Tipo de liquidador
                    </InputLabel>
                    <TextField
                      id="outlined-basic"
                      variant="standard"
                      labelId="liquidador"
                      name="liquidator_type"
                      value={subOp?.liquidator_type}
                      disabled={true}
                    />
                  </FormControl>
                  {subOp.liquidator_type === 'DELIVERY' && (
                    <>
                      {/* DELIVERY */}
                      <TextField
                        error={errors.assignedDelivery}
                        value={subOp?.assignedDelivery?.name}
                        label="Delivery"
                        variant="standard"
                        disabled={true}
                        fullWidth={true}
                        InputLabelProps={{
                          style: {
                            color: `${theme.palette.inputText.main}`,
                            position: 'relative',
                            top: '-10px',
                          },
                        }}
                        sx={{
                          input: {
                            color: `${theme.palette.inputText.main}`,
                          },
                          '& .MuiInputLabel-root': { color: '#D3D6DA' },
                        }}
                      />
                      {/* DIRECCION */}
                      <TextField
                        error={errors.address}
                        label="Dirección"
                        variant="standard"
                        type="text"
                        name="address"
                        fullWidth={true}
                        InputLabelProps={{
                          style: {
                            color: `${theme.palette.inputText.main}`,
                            position: 'relative',
                            top: '-10px',
                          },
                        }}
                        sx={{
                          input: {
                            color: `${theme.palette.inputText.main}`,
                          },
                          '& .MuiInputLabel-root': { color: '#D3D6DA' },
                          borderBottom: '1px solid #D3D6DA',
                        }}
                        InputProps={{ disableUnderline: !errors.address }}
                        value={operation.address}
                        disabled
                      />
                      <TextField
                        error={errors.addressDetail}
                        variant="standard"
                        label="Detalles de la dirección"
                        name="addressDetail"
                        fullWidth={true}
                        InputLabelProps={{
                          style: {
                            color: `${theme.palette.inputText.main}`,
                            position: 'relative',
                            top: '-10px',
                          },
                        }}
                        sx={{
                          input: {
                            color: `${theme.palette.inputText.main}`,
                          },
                          '& .MuiInputLabel-root': { color: '#D3D6DA' },
                          borderBottom: '1px solid #D3D6DA',
                        }}
                        value={operation?.addressDetail}
                        disabled
                      />
                    </>
                  )}
                </SubOperationFormContainer>{' '}
                <div style={{ display: 'flex' }}>
                  {/* MONTO DE ENTRADA */}
                  <p>Monto de entrada</p>
                  <TextField
                    variant="filled"
                    value={
                      subOp?.input_amount
                        ? formatNumber(subOp?.input_amount)
                        : ''
                    }
                    InputProps={{
                      disableUnderline: true,
                      // startAdornment: (
                      //   <InputAdornment position="start">$</InputAdornment>
                      // ),
                      style: {
                        color: `black`,
                        height: '46px',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        paddingBottom: '12px',
                      },
                    }}
                    error={errors.input_amount}
                    disabled={true}
                    placeholder="Monto de entrada"
                  />
                  {/* MONTO DE SALIDA */}
                  <p>Monto de salida</p>
                  <TextField
                    variant="filled"
                    value={
                      subOp?.output_amount
                        ? formatNumber(subOp?.output_amount)
                        : ''
                    }
                    InputProps={{
                      disableUnderline: true,
                      // startAdornment: (
                      //   <InputAdornment position="start">$</InputAdornment>
                      // ),
                      style: {
                        color: `black`,
                        height: '46px',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        paddingBottom: '12px',
                      },
                    }}
                    error={errors.input_amount}
                    disabled={true}
                    placeholder="Monto de entrada"
                  />
                </div>
              </>
            ))
          ) : (
            <SubOperationFormContainer>
              {/* LIQUIDADOR */}
              <FormControl
                variant="standard"
                style={{ marginTop: '15px' }}
                fullWidth
                error={errors.liquidator_type}
              >
                <InputLabel
                  sx={{
                    color: `${theme.palette.inputText.main}`,
                    position: 'relative',
                    top: '-25px',
                  }}
                  id="liquidador"
                >
                  Tipo de liquidador
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="standard"
                  labelId="liquidador"
                  name="liquidator_type"
                  value={operation?.liquidator_type}
                  disabled={true}
                />
              </FormControl>
              {/* DELIVERY */}

              <TextField
                error={errors.assignedDelivery}
                value={operation?.assignedDelivery?.name}
                label="Delivery"
                variant="standard"
                disabled={true}
                fullWidth={true}
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                    position: 'relative',
                    top: '-10px',
                  },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                }}
              />
              {/* DIRECCION */}
              <TextField
                error={errors.address}
                label="Dirección"
                variant="standard"
                type="text"
                name="address"
                fullWidth={true}
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                    position: 'relative',
                    top: '-10px',
                  },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  borderBottom: '1px solid #D3D6DA',
                }}
                InputProps={{ disableUnderline: !errors.address }}
                value={operation.address}
                disabled
              />

              <TextField
                error={errors.addressDetail}
                variant="standard"
                label="Detalles de la dirección"
                name="addressDetail"
                fullWidth={true}
                InputLabelProps={{
                  style: {
                    color: `${theme.palette.inputText.main}`,
                    position: 'relative',
                    top: '-10px',
                  },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  borderBottom: '1px solid #D3D6DA',
                }}
                value={operation?.addressDetail}
                disabled
              />
            </SubOperationFormContainer>
          )}

          <BottomOperationFormContainer>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                gap: '30px',
              }}
            >
              {/* ANOMALIA ENTRADA */}
              {inputAnomalie ? (
                <TextField
                  label="Anomalía de entrada"
                  variant="standard"
                  name="input_anomaly"
                  fullWidth={true}
                  InputLabelProps={{
                    style: {
                      color: `${theme.palette.inputText.main}`,
                      position: 'relative',
                      top: '-10px',
                    },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  disabled
                  value={
                    inputAnomalie[0]?.amount
                      ? formatNumber(inputAnomalie[0]?.amount)
                      : ''
                  }
                />
              ) : null}

              {/* ANOMALIA SALIDA */}
              {outputAnomalie ? (
                <TextField
                  type="text"
                  label="Anomalía de salida"
                  variant="standard"
                  name="output_anomaly"
                  disabled
                  fullWidth={true}
                  InputLabelProps={{
                    style: {
                      color: `${theme.palette.inputText.main}`,
                      position: 'relative',
                      top: '-10px',
                    },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  value={
                    outputAnomalie[0]?.amount
                      ? formatNumber(outputAnomalie[0]?.amount)
                      : ''
                  }
                />
              ) : null}
            </div>

            {/* COMENTARIOS */}
            <TextField
              label="Comentarios"
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                  position: 'relative',
                  top: '-10px',
                },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              variant="standard"
              type="text"
              name="comments"
              fullWidth={true}
              value={operation?.comments}
              disabled={true}
            />
          </BottomOperationFormContainer>
        </>
      )}
    </>
  );
};

export default DetailForm;
