/* eslint-disable no-unused-vars */
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';

export default function validateOperation(operation) {
  const {
    input_currency,
    output_currency,
    input_amount,
    output_amount,
    liquidator_type,
    client,
    clientToTransfer,
    address,
    assignedDelivery,
    exchange_rate,
    service_provider,
    paidTeamMember,
    broker,
    currency,
    balance,
    userId,
    subOperations,
    commission,
  } = operation;
  const { operation_type } = operation;

  let areThereErrors = false;
  let errors = {
    client: false,
    clientToTransfer: false,
    operation_type: false,
    input_currency: false,
    input_amount: false,
    output_currency: false,
    output_amount: false,
    liquidator_type: false,
    input_anomaly: false,
    output_anomaly: false,
    assignedDelivery: false,
    address: false,
    exchange_rate: false,
    paidTeamMember: false,
    broker: false,
    balance: false,
    currency: false,
    userId: false,
    commission: false,
  };
  let message = [];

  if (operationFinacialOrinstitutional(operation_type)) {
    if (
      operation_type === 'PAGO SERV/PROV' ||
      operation_type === 'PAGO SUELDOS' ||
      operation_type === 'LIQ.COMISIONES'
    ) {
      if (!output_currency) {
        areThereErrors = true;
        errors.output_currency = true;
        message.push('Divisa de salida');
      }
      if (!output_amount) {
        areThereErrors = true;
        errors.output_amount = true;
        message.push('Monto de salida');
      }

      if (operation_type === 'PAGO SERV/PROV') {
        if (!service_provider) {
          areThereErrors = true;
          errors.serviceProvider = true;
          message.push('Servicio / Proveedor');
        }
      }

      if (operation_type === 'PAGO SUELDOS') {
        if (!paidTeamMember) {
          areThereErrors = true;
          errors.paidTeamMember = true;
          message.push('Equipo');
        }
      }

      if (operation_type === 'LIQ.COMISIONES') {
        if (!broker) {
          areThereErrors = true;
          errors.broker = true;
          message.push('Corredor');
        }
      }
    }
    if (operation_type === 'INGRESO CTA.CORRIENTE') {
      if (!input_currency) {
        areThereErrors = true;
        errors.input_currency = true;
        message.push('Divisa de entrada');
      }
      if (!input_amount) {
        areThereErrors = true;
        errors.input_amount = true;
        message.push('Monto de entrada');
      }
      if (!client) {
        areThereErrors = true;
        errors.client = true;
        message.push('Cliente');
      }
    }
    if (
      operation_type === 'ENTREGO/SALE POS.DELIVERY' ||
      operation_type === 'RECIBO/ENTRA POS.DELIVERY'
    ) {
      if (!currency) {
        areThereErrors = true;
        errors.currency = true;
        message.push('Divisa');
      }
      if (!balance) {
        areThereErrors = true;
        errors.balance = true;
        message.push('Balance');
      }
      if (!userId) {
        areThereErrors = true;
        errors.userId = true;
        message.push('Delivery');
      }
    }
    if (operation_type === 'TRASPASO CTA.CORRIENTE') {
      if (!input_currency) {
        areThereErrors = true;
        errors.input_currency = true;
        message.push('Divisa');
      }
      if (!input_amount) {
        areThereErrors = true;
        errors.input_amount = true;
        message.push('Monto');
      }
      if (!client) {
        areThereErrors = true;
        errors.client = true;
        message.push('Emisor');
      }
      if (!clientToTransfer) {
        areThereErrors = true;
        errors.clientToTransfer = true;
        message.push('Receptor');
      }
    }
  } else {
    // Validar suboperaciones
    let totalInput = 0;
    let totalOutput = 0;
    let totalAnomalyInput = 0;
    let totalAnomalyOutput = 0;
    if (subOperations.length > 0) {
      subOperations.forEach((subOp) => {
        totalInput += subOp.input_amount;
        totalOutput += subOp.output_amount;
        totalAnomalyInput += subOp.input_anomaly;
        totalAnomalyOutput += subOp.output_anomaly;
        if (
          subOp.input_amount === null ||
          subOp.input_amount === undefined ||
          subOp.input_amount === ''
        ) {
          areThereErrors = true;
          errors.input_amount = true;
          message.push('Monto de entrada');
        }

        if (
          subOp.output_amount === null ||
          subOp.output_amount === undefined ||
          subOp.output_amount === ''
        ) {
          areThereErrors = true;
          errors.output_amount = true;
          message.push('Monto de salida');
        }

        if (!subOp.liquidator_type) {
          areThereErrors = true;
          errors.liquidator_type = true;
          message.push('Tipo de liquidador');
        }
        if (!subOp.liquidator_type) {
          areThereErrors = true;
          errors.liquidator_type = true;
          message.push('Tipo de liquidador');
        }
      });
    }

    // Validar montos de suboperaciones no superen al de operacion...
    if (subOperations.length > 0 && totalInput !== input_amount) {
      areThereErrors = true;
      alert(
        'La suma total de los montos de entrada de las SUBOPERACIONES debe ser igual a los monto de entrada de la OPERACION',
      );
    }

    if (subOperations.length > 0 && totalOutput !== output_amount) {
      areThereErrors = true;
      alert(
        'La suma total de los montos de salida de las SUBOPERACIONES debe ser igual a los monto de salida de la  OPERACION',
      );
    }
    if (subOperations.length > 0 && totalAnomalyInput > input_amount) {
      areThereErrors = true;
      alert(
        ' La suma total de las anomalias de entrada las SUBOPERACIONES debe ser igual o menor al monto de entrada de la OPERACIÓN',
      );
    }

    if (subOperations.length > 0 && totalAnomalyOutput > output_amount) {
      areThereErrors = true;
      alert(
        ' La suma total de las anomalias de salida de las SUBOPERACIONES debe ser igual o menor al monto de entrada de la OPERACIÓN',
      );
    }
    if (!input_currency) {
      areThereErrors = true;
      errors.input_currency = true;
      message.push('Divisa de entrada');
    }
    if (!input_amount) {
      areThereErrors = true;
      errors.input_amount = true;
      message.push('Monto de entrada');
    }
    if (!output_currency) {
      areThereErrors = true;
      errors.output_currency = true;
      message.push('Divisa de salida');
    }
    if (!output_amount) {
      areThereErrors = true;
      errors.output_amount = true;
      message.push('Monto de salida');
    }
    if (!client) {
      areThereErrors = true;
      errors.client = true;
      message.push('Cliente');
    }
    if (subOperations.length <= 0 && !liquidator_type) {
      areThereErrors = true;
      errors.liquidator_type = true;
      message.push('Tipo de liquidador');
    }
    if (!exchange_rate) {
      areThereErrors = true;
      errors.exchange_rate = true;
      message.push('Tipo de cambio');
    }
  }
  if (areThereErrors) {
    message = `El campo ${message.join(', ')} no puede ser un campo vacio`;
    return [errors, message];
  } else {
    return false;
  }
}
